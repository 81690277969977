import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showPass: boolean;
  avilableRoles: any[];
  prefilledData: {
    fname: string;
    email: string;
    password: string;
    role: string;
    checkbox: boolean;
    roleId: string;
  };
  rolesOpen:boolean;
  passwordError: string;
  verifyLoader: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  rolesApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      showPass: false,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      avilableRoles: [],
      prefilledData: { fname: '', email: '', password: '', role: '', checkbox: false, roleId: "" },
      rolesOpen:false,
      passwordError: "",
      verifyLoader: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getDynamicRoles();
    let remember = localStorage.getItem("rememberMe");
    if (remember) {
      this.setState({ prefilledData: JSON.parse(remember) });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleUserCredentials(message: Message) {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
    const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));

    if (!countryCode && userName && password) {
      this.setState({
        email: userName,
        password: password,
        checkedRememberMe: true,
      });

      //@ts-ignore
      this.txtInputEmailProps.value = userName;

      //@ts-ignore
      this.txtInputPasswordProps.value = password;

      this.CustomCheckBoxProps.isChecked = true;
    }
  }

  async handleRestAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    if (apiRequestCallId != null) {
      if (this.isValidationApiCall(apiRequestCallId, responseJson)) {
        this.processValidationApiResponse(responseJson);
      }
  
      if (this.isRolesApiCall(apiRequestCallId, responseJson)) {
        this.processRolesApiResponse(responseJson);
      }
  
      if (this.isEmailLoginApiCall(apiRequestCallId)) {
        await this.processEmailLoginResponse(responseJson, errorResponse);
      }
    }
  }

  isValidationApiCall(apiRequestCallId: string, responseJson: any): boolean {
    return apiRequestCallId === this.validationApiCallId && responseJson !== undefined;
  }
  
  processValidationApiResponse(responseJson: any) {
    const arrayholder = responseJson.data;
  
    if (arrayholder && arrayholder.length !== 0) {
      const regexData = arrayholder[0];
      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }
  
  isRolesApiCall(apiRequestCallId: string, responseJson: any): boolean {
    return apiRequestCallId === this.rolesApiCallId && responseJson !== undefined;
  }
  
  processRolesApiResponse(responseJson: any) {
    const arrayholder = responseJson.data;
    this.setState({ avilableRoles: arrayholder });
  }
  
  isEmailLoginApiCall(apiRequestCallId: string): boolean {
    return apiRequestCallId === this.apiEmailLoginCallId;
  }
  
  async processEmailLoginResponse(responseJson: any, errorResponse: any) {
    this.setState({
      verifyLoader: false
    })
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.handleSuccessfulLogin(responseJson);
    } else {
      this.handleLoginError(responseJson);
      this.parseApiCatchErrorResponse(errorResponse);
      
    }
  }
  
  async handleSuccessfulLogin(responseJson: any) {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    this.saveLoggedInUserData(responseJson);
  
    localStorage.setItem("userID", responseJson.meta.id);
    localStorage.setItem("userRole", responseJson.meta.user_role);
  
    await setStorageData("setProfile", JSON.stringify(responseJson.meta.set_profile));
    await setStorageData("companyName", JSON.stringify(responseJson.meta.company_name));
    this.navigateToNextPage(responseJson.meta.set_profile, responseJson.meta.user_role, responseJson.meta.company_name);
  
    if (this.state.checkedRememberMe) {
      setStorageData('rememberMe', JSON.stringify(this.state.prefilledData));
    } else { localStorage.removeItem('rememberMe') }
  }
  navigateToNextPage(setProfile: boolean, role: string, companyName: string) {
    let navigationPath = ""
    
    if(role === "recruiter") {
      navigationPath = this.handleCondition((setProfile && companyName), "LandingPage", this.handleCondition(setProfile, "CompanyEditDetails", "UserEditProfile"))
    } else if(role === "candidate") {
      navigationPath = setProfile ? "LandingPage" : "EditProfile"
    }

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), navigationPath);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNaviagteTo(role: string) {
    return role === 'candidate' ? "EditProfile" : "UserEditProfile"
  }

  disableButton = (values: {
    fname: string;
    email: string;
    password: string;
    roleId: string;
    role: string;
    checkbox: string;
  }) => {
    const disableBtn = this.state.verifyLoader || !values.email || !values.password || !values.role 
    return disableBtn
  }
  
  handleLoginError(responseJson: any) {
    this.sendLoginFailMessage();
    if (responseJson.errors.length > 0) {
      const errorMessage = (Object.values(responseJson?.errors[0])[0] as string) || 'Something went wrong';
      if(errorMessage === "Incorrect password") {
        this.setState({
          passwordError: configJSON.incorrectPassword
        })
        return
      }
      toast.error(errorMessage);
    }
  }
  

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  handleCondition = (condition:any, truePart:any, falsePart:any) => {
    return condition ? truePart : falsePart
  }

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };
  handleRoleClose =() =>{ this.setState({ rolesOpen: false }) }

  handleRoleClick =() =>{
    this.setState({rolesOpen:!this.state.rolesOpen})
  }
  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling

  goToSignUpPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleLoginSubmit = (values: any) => {
    this.setState({
      verifyLoader: true
    })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email,
      password: values.password,
      role_id: values.roleId || this?.state?.avilableRoles?.[0]?.id
    };

    if (values.checkbox) {
      this.setRememberMe(true);
      this.setState({ prefilledData: values })
    }

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPointSecond
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    // return true;
  }
  goToTermsPage(val: string) {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'TermsConditionsDetail');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData('TPolicy', val);
    this.send(message);
  }

  getDynamicRoles = () => {
    const headers = {
      "Content-Type": configJSON.rolesApiContentType,
    };

    const getRolesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rolesApiCallId = getRolesMsg.messageId;

    getRolesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetRoles
    );

    getRolesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getRolesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.rolesApiMethodType
    );
    runEngine.sendMessage(getRolesMsg.id, getRolesMsg);
  }

  handleShowPass = (val: boolean) => {
    this.setState({
      showPass: val,
    })
  }

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      this.handleUserCredentials(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      await this.handleRestAPIResponse(message);
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
