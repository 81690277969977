// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import { Grid } from "@material-ui/core";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import * as Ably from 'ably';
import { AblyProvider,ChannelProvider } from 'ably/react';
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import AppTopHeader from "../../components/src/AppTopHeader.web";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfscorecalculations1 from "../../blocks/cfscorecalculations1/src/Cfscorecalculations1";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import ForgotPasswordBlock from "../../blocks/forgot-password/src/ForgotPasswordBlock.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Followers from "../../blocks/followers/src/Followers.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetailP2.web";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue1 from "../../blocks/catalogue/src/Catalogue1.web";
import Upvotedownvote from "../../blocks/upvotedownvote/src/Upvotedownvote";
import Automatedemailsending from "../../blocks/automatedemailsending/src/Automatedemailsending";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Cfscorecalculations from "../../blocks/cfscorecalculations/src/Cfscorecalculations";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Cfdatamigration13 from "../../blocks/cfdatamigration13/src/Cfdatamigration13";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import Cfretrievejobfromindeed from "../../blocks/cfretrievejobfromindeed/src/Cfretrievejobfromindeed";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Cfrecruitersdashboard from "../../blocks/cfrecruitersdashboard/src/Cfrecruitersdashboard";
import Cfplaceholderscrapandlistthejobfromotherplatforms from "../../blocks/cfplaceholderscrapandlistthejobfromotherplatforms/src/Cfplaceholderscrapandlistthejobfromotherplatforms";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import UserProfileEdit from "../../blocks/user-profile-basic/src/UserProfileEdit.web";
import AddWorkExperience from "../../blocks/user-profile-basic/src/AddWorkExperience.web";
import JobListing from "../../blocks/joblisting/src/JobListing.web";
import LeaderBoard from "../../blocks/leaderboard/src/Leaderboard.web"
import RecruiterProfile from "../../blocks/user-profile-basic/src/RecruiterProfile.web";
import ProfilePageForRecruiter from "../../blocks/user-profile-basic/src/ProfilePageForRecruiter.web";
import RecruiterProfile1 from "../../blocks/user-profile-basic/src/RecruiterProfile1.web";
import JobDetailsListing from "../../blocks/joblisting/src/JobDetailsListing.web";
import AdvancedSearchResults from "../../blocks/advancedsearch/src/AdvancedSearchResults";
import Message  from "../../framework/src/Message";
import {client} from '../../components/src/Utils.web'
import UserEditProfile1 from  "../../blocks/user-profile-basic/src/UserProfileEdit1.web"
import CompanyDetailsEdit from "../../blocks/user-profile-basic/src/CompanyDetailsEdit.web"
import { getLoginToken,isProfileDetailsUpdated } from "../../components/src/Utils.web";
import FloatingChat from "../../components/src/FloatingChat.web";

const routeMap = {
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  // Uploadmedia3: {
  //   component: Uploadmedia3,
  //   path: "/Uploadmedia3"
  // },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: "/DownloadOptions"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  Cfscorecalculations1: {
    component: Cfscorecalculations1,
    path: "/Cfscorecalculations1"
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: "/DocumentOpener"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Followers: {
    component: Followers,
    path: "/Followers",
    roles: ["candidate", "recruiter"]
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Gamification: {
    component: Gamification,
    path: "/Gamification"
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: "/RecommendationEngine"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions",
    private: true,
    roles: ["candidate", "recruiter"]
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Upvotedownvote: {
    component: Upvotedownvote,
    path: "/Upvotedownvote"
  },
  Automatedemailsending: {
    component: Automatedemailsending,
    path: "/Automatedemailsending"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2",
    private: false,
    roles: ["candidate", "recruiter"],
  },
  LeaderBoard:{
    component: LeaderBoard,
    path: "/LeaderBoard",
    private: false,
    roles: ["candidate", "recruiter"],
  },
  Comment: {
    component: Comment,
    path: "/Comment"
  },
  CreateComment: {
    component: CreateComment,
    path: "/CreateComment"
  },
  Dataencryption2: {
    component: Dataencryption2,
    path: "/Dataencryption2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Cfdatamigration13: {
    component: Cfdatamigration13,
    path: "/Cfdatamigration13"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  // AdvancedSearch: {
  //   component: AdvancedSearch,
  //   path: "/AdvancedSearch"
  // },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: "/AutomaticRenewals"
  },
  CommunityForum: {
    component: CommunityForum,
    path: "/CommunityForum"
  },
  Cfretrievejobfromindeed: {
    component: Cfretrievejobfromindeed,
    path: "/Cfretrievejobfromindeed"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Cfrecruitersdashboard: {
    component: Cfrecruitersdashboard,
    path: "/Cfrecruitersdashboard"
  },
  Cfplaceholderscrapandlistthejobfromotherplatforms: {
    component: Cfplaceholderscrapandlistthejobfromotherplatforms,
    path: "/Cfplaceholderscrapandlistthejobfromotherplatforms"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3",
    private:false, 
    roles: ["candidate"]
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: "/DownloadOptions"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  Cfscorecalculations1: {
    component: Cfscorecalculations1,
    path: "/Cfscorecalculations1"
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: "/DocumentOpener"
  },
  ForgotPassword: {
    component: ForgotPasswordBlock,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Gamification: {
    component: Gamification,
    path: "/Gamification"
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: "/RecommendationEngine"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  CatalogueOne: {
    component: Catalogue1,
    path: "/CatalogueOne/:jobId",
    private: false,
    roles: ["candidate"]
  },
  Catalogue: {
    component: Catalogue1,
    path: "/Catalogue",
    private: false,
    roles: ["candidate"]
  },
  Upvotedownvote: {
    component: Upvotedownvote,
    path: "/Upvotedownvote"
  },
  Automatedemailsending: {
    component: Automatedemailsending,
    path: "/Automatedemailsending"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Cfscorecalculations: {
    component: Cfscorecalculations,
    path: "/Cfscorecalculations",
    private: true,
    roles: ["recruiter","candidate"]
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Comment: {
    component: Comment,
    path: "/Comment"
  },
  CreateComment: {
    component: CreateComment,
    path: "/CreateComment"
  },
  Dataencryption2: {
    component: Dataencryption2,
    path: "/Dataencryption2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications",
    private: true,
    roles: ["recruiter","candidate"]
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Cfdatamigration13: {
    component: Cfdatamigration13,
    path: "/Cfdatamigration13"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  LandingPagePost: {
    component: LandingPage,
    private: false,
    roles: ["candidate", "recruiter"],
    path: "/LandingPagePost/:postId"
  },
  LandingPage: {
    component: LandingPage,
    private: false,
    roles: ["candidate", "recruiter"],
    path: "/LandingPage"
  },
  AdvancedSearchResults: {
    component: AdvancedSearchResults,
    path: "/AdvancedSearchResults/:search_key",
    private: false,
    roles: ["candidate", "recruiter"],
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: "/AutomaticRenewals"
  },
  CommunityForum: {
    component: CommunityForum,
    path: "/CommunityForum"
  },
  Cfretrievejobfromindeed: {
    component: Cfretrievejobfromindeed,
    path: "/Cfretrievejobfromindeed"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Cfrecruitersdashboard: {
    component: Cfrecruitersdashboard,
    path: "/Cfrecruitersdashboard"
  },
  Cfplaceholderscrapandlistthejobfromotherplatforms: {
    component: Cfplaceholderscrapandlistthejobfromotherplatforms,
    path: "/Cfplaceholderscrapandlistthejobfromotherplatforms"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
    private: false,
    roles: ["candidate", "recruiter"],
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  EditProfile:{
    component:UserProfileEdit,
   path:"/EditProfile",
   private: true,
   roles: ["candidate"]
  },
  UserEditProfile:{
    component:UserEditProfile1,
    path:"/UserEditProfile",
    private:true,
    roles:["recruiter"]
  },
  CompanyEditDetails:{
    component:CompanyDetailsEdit,
    path:"/CompanyEditDetails",
    private:true,
    roles:["recruiter"]

  },
   AddWorkExperience:{
    component:AddWorkExperience,
   path:"/AddWorkExperience",
  private: true,
  roles: ["candidate"]
  }, 
  EditWorkExperience:{
    component:AddWorkExperience,
   path:"/EditWorkExperience/:workExperienceId",
  private: true,
  roles: ["candidate"]
  }, 
  JobListingRecruiter:{
    component:JobListing,
   path:"/JobListingRecruiter",
  private: true,
  roles: ["recruiter"]
  },
  Talent:{
    component:Cfrecruitersdashboard,
   path:"/Talent",
  private: true,
  roles: ["recruiter"]
  },
  Messaging:{
    component:Chat,
    path:"/Messaging",
    private: true,
    roles: ["recruiter","candidate"]
  },
  JobApplicantProfile:{
    component:RecruiterProfile,
   path:"/JobApplicantProfile",
   private: true,
   roles: ["candidate"]
  },
  EditDealExperience:{
    component:EducationalUserProfile,
   path:"/EditDealExperience/:navigationBarTitleText",
   private: true,
   roles: ["candidate"]
  },
  RecruiterProfile:{
    component:ProfilePageForRecruiter,
   path:"/RecruiterProfile",
   private: true,
   roles: ["recruiter"]
  },
  Profile:{
    component:RecruiterProfile1,
   path:"/Profile/:navigationBarTitleText",
   private: true,
   roles: ["recruiter", "candidate"]
  },
  JobDetailListing:{
    component:JobDetailsListing,
   path:"/JobDetailListing",
   private: true,
   roles: ["recruiter"]
  },
};

// const client = new Ably.Realtime({ key: 'W5_OUA.cDKURw:voT3_JNTi6vFzatY7ceWH7JVsXXbLD8n79PODfu61e8' });
client.connection.on('connected', () => {
  console.log('Connected to Ably!');
});
class App extends Component {

  render() {

    const excludedRoutes = ["EmailAccountRegistration", "EmailAccountLoginBlock", "ForgotPassword"];
    const shouldRenderAppTopHeader = !excludedRoutes.some(route => window.location.pathname.toLowerCase().includes(route.toLowerCase()));
    const isWhiteBackground = ["Catalogue"].some(route => window.location.pathname.toLowerCase().includes(route.toLowerCase()))
    const url = new URL(window.location.href)
    const shouldRenderFloatingChat = !["EmailAccountRegistration", "EmailAccountLoginBlock", "ForgotPassword","Messaging","TermsConditionsDetail"].some(itemName => url.pathname.toLowerCase().includes(itemName.toLowerCase()));

    return (
<AblyProvider client={client}>
<ChannelProvider channelName="get-started">
      <Grid container style={{
        height: "100vh",
        overflowX: "hidden",
        display:"flex",
        justifyContent: "center"
      }}>
        {shouldRenderAppTopHeader && <AppTopHeader />}
        <div className={shouldRenderAppTopHeader ? isWhiteBackground ? "bodyOfApp whiteBG" :  "bodyOfApp": isWhiteBackground ? "bodyOfApp1 whiteBG" : "bodyOfApp1"}>
          {WebRoutesGenerator({ routeMap, })}
        </div>
        {getLoginToken() && shouldRenderFloatingChat && isProfileDetailsUpdated() && <FloatingChat /> }
        <ModalContainer />
      </Grid>
      </ChannelProvider>
      </AblyProvider>
    );
  }
}

export default App;